<template>
    <Authentication Title="Forgot Password" Description="Enter your email to reset your password"
        @togglePopup="$emit('togglePopup', $event); resetInputs();"
    >
        <div class="input-group" :class="{invalid: emailError.length > 0}">
            <input type="text" id="user-email" v-model="email" 
            :class="{hasValue : email.length > 0, invalid: emailError.length > 0}">
            <label for="user-email">Email Address</label>
            <div class="artificial-outline"></div>
            <p v-if="emailError.length > 0" class="error">{{emailError}}</p>
        </div>
        
        <div class="redirect">
            <p>Have an account? <button @click="$emit('togglePopup', 'signin'); resetInputs();">Login</button></p>
            <p>New here? <button @click="$emit('togglePopup', 'register'); resetInputs();">Sign Up</button></p>
        </div>
        <button @click="resetPassword()" :disabled="processing">{{processing? 'Resetting...' : 'Reset'}}</button>
    </Authentication>
</template>

<script>
import Authentication from './Authentication.vue';
import authentication from '../../services/authentication';
import Validators from '../../helpers/Validators';

export default {
    name: "ForgotPassword",
    components: {
        Authentication
    },
    data() {
        return {
            Rotate: false,
            email: "",
            emailError: "",
            processing: false,
        }
    },
    methods: {

        resetInputs() {
            this.email = "";
        },

        resetPassword() {
            const forEmail = new Validators();
            forEmail.required(this.email);
            forEmail.validEmail(this.email);
            this.emailError = forEmail.errors;

            if(this.emailError.length === 0) {
                this.processing = true;
                let body = {
                    "email": this.email
                }

                body = JSON.stringify(body);

                authentication.realtorForgotPassword(body)
                .then(data => {
                    if(data.success) {
                        this.$toast.success(data.success);
                        this.resetInputs();
                        return this.$emit('togglePopup', '');
                    } else {
                        this.$toast.error(data.error);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.$toast.error("please check your network and refresh the page");
                })
                .finally(() => {
                    this.processing = false;
                })
            }
        },
    }
}
</script>

<style scoped>
    


</style>