<template>
  <Authentication
    Title="Create Account"
    Description="Become a Realtor"
    @togglePopup="
      $emit('togglePopup', $event);
      resetInputs();
    "
  >
    <div class="input-group" :class="{ invalid: firstnameError.length > 0 }">
      <input
        type="text"
        id="fname"
        v-model="firstname"
        :class="{
          hasValue: firstname.length > 0,
          invalid: firstnameError.length > 0,
        }"
      />
      <label for="fname">First Name</label>
      <div class="artificial-outline"></div>
      <p v-if="firstnameError.length > 0" class="error">{{ firstnameError }}</p>
    </div>

    <div class="input-group" :class="{ invalid: lastnameError.length > 0 }">
      <input
        type="text"
        id="lname"
        v-model="lastname"
        :class="{
          hasValue: lastname.length > 0,
          invalid: lastnameError.length > 0,
        }"
      />
      <label for="lname">Last Name</label>
      <div class="artificial-outline"></div>
      <p v-if="lastnameError.length > 0" class="error">{{ lastnameError }}</p>
    </div>

    <div class="input-group" :class="{ invalid: emailError.length > 0 }">
      <input
        type="text"
        id="register-email"
        v-model="email"
        :class="{ hasValue: email.length > 0, invalid: emailError.length > 0 }"
      />
      <label for="register-email">Email Address</label>
      <div class="artificial-outline"></div>
      <p v-if="emailError.length > 0" class="error">{{ emailError }}</p>
    </div>

    <div
      class="input-group"
      :class="{ invalid: phoneError.length > 0 }"
    >
      <input
        type="text"
        id="phone"
        v-model="phone"
        :class="{ hasValue: phone.length > 0, invalid: phoneError.length > 0 }"
      />
      <label for="phone">Phone Number</label>
      <div class="artificial-outline"></div>
      <p v-if="phoneError.length > 0" class="error">{{ phoneError }}</p>
    </div>

    <div class="input-group" :class="{ invalid: passwordError.length > 0 }">
      <input
        :type="inputType(showPassword)"
        id="register-password"
        v-model="password"
        :class="{
          hasValue: password.length > 0,
          invalid: passwordError.length > 0,
        }"
      />
      <label for="register-password">Password</label>
      <div class="artificial-outline"></div>
      <img
        src="@/assets/images/eye.svg"
        class="icon"
        @click="showPassword = !showPassword"
        v-if="!showPassword"
      />
      <img
        src="@/assets/images/eye-slash.svg"
        class="icon"
        @click="showPassword = !showPassword"
        v-if="showPassword"
      />
      <p v-if="passwordError.length > 0" class="error">{{ passwordError }}</p>
    </div>

    <div
      class="input-group"
      :class="{ invalid: confirmPasswordError.length > 0 }"
    >
      <input
        :type="inputType(showCPassword)"
        id="cpassword"
        v-model="confirmPassword"
        :class="{
          hasValue: confirmPassword.length > 0,
          invalid: confirmPasswordError.length > 0,
        }"
      />
      <label for="cpassword">Confirm Password</label>
      <div class="artificial-outline"></div>
      <img
        src="@/assets/images/eye.svg"
        class="icon"
        @click="showCPassword = !showCPassword"
        v-if="!showCPassword"
      />
      <img
        src="@/assets/images/eye-slash.svg"
        class="icon"
        @click="showCPassword = !showCPassword"
        v-if="showCPassword"
      />
      <p v-if="confirmPasswordError.length > 0" class="error">
        {{ confirmPasswordError }}
      </p>
    </div>
    <div
      class="input-group"
      v-if="hideCodeInput"
      :class="{ invalid: codeError.length > 0 }"
    >
      <input
        type="text"
        id="code"
        v-model="code"
        :class="{ hasValue: code.length > 0, invalid: codeError.length > 0 }"
      />
      <label for="code">Invite Code</label>
      <div class="artificial-outline"></div>
      <p v-if="codeError.length > 0" class="error">{{ codeError }}</p>
    </div>

    <div class="redirect">
      <!-- TODO: you should return to this paragraph with class terms only when the link to
            terms and conditions is availabld -->
      <!-- <p class="terms">
                <input type="checkbox" v-model="agreeToTerms">
                <span>I agree to the Shelta’s terms and conditions</span>
            </p> -->
      <p class="terms">
        By Signing Up, I agree to the Shelta’s
        <span class="tc" @click="openTermsCondition"
          > terms & conditions.</span>
      </p>
      <!-- data-toggle="modal" data-target="#termsc" -->
      <p>
        Have an account?
        <button
          @click="
            $emit('togglePopup', 'signin');
            resetInputs();
          "
        >
          Login
        </button>
      </p>
    </div>

    <button @click="register()" :disabled="processing">
      {{ processing ? "Creating Account..." : "Create my Account" }}
    </button>
    <teamsCondition></teamsCondition>
  </Authentication>
</template>

<script>
import Authentication from "./Authentication.vue";
import authentication from "../../services/authentication.js";
import Validators from "../../helpers/Validators";
import teamsCondition from "./TermsCondtions.vue";

export default {
  name: "Register",
  components: {
    Authentication,
    teamsCondition
  },
  data() {
    return {
      Rotate: false,
      showPassword: false,
      showCPassword: false,
      firstname: "",
      firstnameError: "",
      lastname: "",
      lastnameError: "",
      codeError: "",
      phoneError: "",
      code: "",
      email: "",
      phone: "",
      emailError: "",
      password: "",
      passwordError: "",
      confirmPassword: "",
      confirmPasswordError: "",
      toye: "password",
      processing: false,
      agreeToTerms: false,
      hideCodeInput: false,
    };
  },
  mounted() {
    const ca = this.$route.path;
    if (ca == "/") {
      this.hideCodeInput = true;
    }
  },
  methods: {
    openTermsCondition() {
      return this.$root.$refs.TC.openTCModal();
    },
    inputType(value) {
      if (value === true) {
        return "text";
      } else {
        return "password";
      }
    },

    resetInputs() {
      this.firstname = "";
      this.firstnameError = "";
      this.lastname = "";
      this.lastnameError = "";
      this.email = "";
      this.emailError = "";
      this.phone = "";
      this.phoneError = "";
      this.password = "";
      (this.code = ""), (this.codeError = ""), (this.passwordError = "");
      this.confirmPassword = "";
      this.confirmPasswordError = "";
      this.showPassword = false;
      this.showCPassword = false;
      this.agreeToTerms = false;
    },

    register() {
      const forFirstName = new Validators();
      forFirstName.required(this.firstname);
      forFirstName.onlyLetters(this.firstname);
      this.firstnameError = forFirstName.errors;

      const forLastName = new Validators();
      forLastName.required(this.lastname);
      forLastName.onlyLetters(this.lastname);
      this.lastnameError = forLastName.errors;

      const forEmail = new Validators();
      forEmail.required(this.email);
      forEmail.validEmail(this.email);
      this.emailError = forEmail.errors;

      const forPhone = new Validators();
      forPhone.required(this.phone);
      forPhone.onlyNumbers(this.phone);
      forPhone.checkMinLength(this.phone, 10);
      this.phoneError = forPhone.errors;

      const routePath = this.$router.currentRoute.path;
      if (routePath.includes("/R")) {
        this.code = routePath.slice(1);
      }
      // if (routePath.includes("/R")) {
      //   this.phone = this;
      // }
      const forCode = new Validators();
      forCode.required(this.code);
      this.codeError = forCode.errors;


      const forPassword = new Validators();
      forPassword.required(this.password);
      forPassword.checkMinLength(this.password, 8);
      forPassword.containNumber(this.password);
      this.passwordError = forPassword.errors;

      const forConfirmPassword = new Validators();
      forConfirmPassword.confirmPassword(this.password, this.confirmPassword);
      this.confirmPasswordError = forConfirmPassword.errors;

      if (
        this.firstnameError.length === 0 &&
        this.lastnameError.length === 0 &&
        this.emailError.length === 0 &&
        this.codeError.length === 0 &&
        this.phoneError.length === 0 &&
        this.passwordError.length === 0 &&
        this.confirmPasswordError.length === 0
      ) {
        this.processing = true;

        let body = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          phone: this.phone,
          code: this.code,
        };

        const routePath = this.$router.currentRoute.path;
        if (routePath.includes("/R")) {
          body.code = routePath.slice(1);
        }

        body = JSON.stringify(body);
        authentication
          .registerUser(body)
          .then((data) => {
            if (data.success) {
              data.user.token = data.token;
              this.$store.dispatch("saveUser", data.user);
              this.$toast.success("Your account has been created successfully");
              this.resetInputs();
              this.$router.push("user/dashboard");
            } else {
              this.$toast.error(data.error);
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$toast.error("please check your network and refresh the page");
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
 $primary: #0033ea;
 $white: #ffffff;
 $yellow: #ffb100;
.terms {
  display: flex;
  align-items: center;
}

.terms input[type="checkbox"] {
  margin-right: 10px;
}

// Terms and condtions
.tc {
  color: $yellow;
  font-weight: bold;
  cursor: pointer;
  padding-left: 5px;
}

@media only screen and (max-width: 599px) {
  .terms {
    display: block
  }
  .tc {
    padding-left: 0;
    // margin: 10px 0;
  }
}
// Terms and condtions
</style>
